import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';


export default function ButtonAppBar() {
  return (
    <AppBar position="fixed" style={{ height: '79px', justifyContent: 'center', margin: 0, padding: 0,background: "linear-gradient(90deg, #074143 0%, #0E7E83 100%)" }}/>
  );
}






